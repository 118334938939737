export default [
  /* AXT */
  {
    id: "CRE",
    code: 9,
    detail: 'Procesando',
    color: 'yellow-100',
    summary: 'Procesando',
    icon: 'mdi-sync',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "MRT",
    code: 9,
    detail: 'Procesando',
    color: 'yellow-100',
    summary: 'Procesando',
    icon: 'mdi-sync',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "ART",
    code: 9,
    detail: 'Procesando',
    color: 'yellow-100',
    summary: 'Procesando',
    icon: 'mdi-sync',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "APR",
    code: 13,
    detail: 'Aceptado',
    color: 'blue-100',
    summary: 'Aceptado',
    icon: 'mdi-check',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "OBS",
    code: 13,
    detail: 'Aceptado con reparo',
    color: 'blue-100',
    summary: 'Aceptado',
    icon: 'mdi-check',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "SNT",
    code: 11,
    detail: 'Pendiente',
    color: 'yellow-100',
    summary: 'Pendiente',
    icon: 'mdi-clock',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "FAI",
    code: 11,
    detail: 'Pendiente',
    color: 'yellow-100',
    summary: 'Pendiente',
    icon: 'mdi-clock',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "REJ",
    code: 7,
    detail: 'Rechazado',
    color: 'red-100',
    summary: 'Rechazado',
    icon: 'mdi-close',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "DUP",
    code: 7,
    detail: 'Rechazado',
    color: 'red-100',
    summary: 'Rechazado',
    icon: 'mdi-close',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
      id: "VOI",
      code: 16,
      detail: 'Anulado',
      color: 'grey-200',
      summary: 'Anulado',
      icon: 'mdi-file-document-remove',
      isAX: true,
      countries: ['CL', 'PE']
  },
  {
    id: "INV",
    code: 17,
    detail: 'No confirmado',
    color: 'yellow-100',
    summary: 'No confirmado',
    isAX: true,
    icon: 'mdi-file-clock',
    countries: ['PE', 'CL']
  },
  {
    id: "PRE",
    code: 18,
    detail: 'Por confirmar',
    color: 'blue-100',
    isAX: true,
    summary: 'Por confirmar',
    icon: 'mdi-file-check',
    countries: ['PE', 'CL']
  }
  /* END AXT */
]